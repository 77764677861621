<template>
  <b-row>
    <b-col sm="3">
      <b-card
        class="height-card bg-info-fade"
      >
        <h4 class="mb-1 d-inline-block" ><strong class="mr-1" >Trabajos totales</strong></h4>
        <h2 class="mb-0" ><strong>{{ tasks }}</strong></h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card
        class="height-card bg-light-fade"
      >
        <h4 class="mb-1 d-inline-block" ><strong class="mr-1" >Horas totales</strong></h4>
        <h2 class="mb-0" ><strong>{{ hours }}</strong></h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card
        class="height-card bg-success-fade"
      >
        <h4 class="mb-1 d-inline-block" ><strong class="mr-1" >Trabajos completados</strong></h4>
        <h2 class="mb-0" ><strong>{{ complete }}</strong></h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card
        class="height-card bg-danger-fade"
      >
        <h4 class="mb-1 d-inline-block" ><strong class="mr-1" >Trabajos en proceso</strong></h4>
        <h2 class="mb-0" ><strong>{{ progess }}</strong></h2>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    client: {
      type: String,
      default: '',
    },
    dateini: {
      type: Number,
      default: 0,
    },
    datefin: {
      type: Number,
      default: 0,
    },
    project: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
  },
  components: {
    BCard,
    BCol,
    BRow,
  },
  data() {
    return {
      type: 'line',
    }
  },
  watch: {
    client() {
      this.chargeData()
    },
    project() {
      this.chargeData()
    },
    dateini() {
      this.chargeData()
    },
    datefin() {
      this.chargeData()
    },
    status() {
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      stadistics: 'tasks/getStadistics',
    }),
    tasks() {
      if (this.stadistics) {
        return (this.stadistics.total_tasks) ? this.stadistics.total_tasks : 0
      }
      return 0
    },
    hours() {
      if (this.stadistics) {
        return (this.stadistics.total_hours) ? this.stadistics.total_hours : 0
      }
      return 0
    },
    complete() {
      if (this.stadistics) {
        return (this.stadistics.total_tasks_complete) ? this.stadistics.total_tasks_complete : 0
      }
      return 0
    },
    progess() {
      if (this.stadistics) {
        return (this.stadistics.total_tasks_in_progress) ? this.stadistics.total_tasks_in_progress : 0
      }
      return 0
    },
  },
  methods: {
    ...mapActions({
      getStadistics: 'tasks/getStadistics',
    }),
    chargeData() {
      this.getStadistics({
        client: this.client, project: this.project, date_ini: this.dateini, date_fin: this.datefin, status: this.status,
      })
    },
  },
  async created() {
    let client = (this.currentClient) ? this.currentClient.id : ''
    if (this.client) {
      client = this.client
    }
    await this.chargeData()
  }
}
</script>
<style lang="scss" >
</style>
