<template>
  <div>
    <Stadistics
      :client="clientProp"
      :project="projectProp"
      :dateini="dateiniProp"
      :datefin="datefinProp"
      :status="statusProp"
      v-if="stadistics"
    />
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-1">
          <div
            class="d-flex justify-content-between align-items-center px-1 py-1"
          >
            <h5 class="m-0 font-weight-bolder">{{ "Trabajos" }}</h5>
            <div class="d-flex align-items-center">
              <div>
                <b-link :to="{ name: 'createJob' }">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-1 text-nowrap"
                  >
                    Añadir nuevo trabajo
                  </b-button>
                </b-link>
              </div>

              <span
                class="ml-1 cursor-pointer"
                @click="visibleFilter = !visibleFilter"
              >
                <feather-icon icon="FilterIcon" size="20" />
              </span>
            </div>
          </div>
          <div>
            <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
              <div class="px-2">
                <b-row>
                  <b-col sm="4">
                    <b-input-group
                      class="input-group mt-2"
                      label-for="filter-search"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="searchTerm"
                        type="text"
                        class="d-inline-block"
                        :placeholder="'Búsqueda'"
                        @keydown.enter.native="handleSearch"
                        :label="'Búsqueda'"
                      />
                      <b-button
                        @click="handleSearch"
                        variant="primary"
                        size="sm"
                        class="mr-1 ml-1"
                      >
                        {{ "Buscar" }}
                      </b-button>
                    </b-input-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Fecha inicio'"
                      label-for="filter-fechaini"
                    >
                      <flat-pickr
                        v-model="date_ini"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Fecha fin'"
                      label-for="filter-fechafin"
                    >
                      <flat-pickr
                        v-model="date_fin"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group :label="'Estado'" label-for="filter-order">
                      <v-select
                        v-model="status"
                        label="name"
                        :filterable="false"
                        :searchable="false"
                        :options="selectStatus"
                      >
                        <template slot="option" slot-scope="option">
                          {{ statusJobsText[option.name] }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ statusJobsText[option.name] }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group :label="'Clientes'" label-for="filter-client">
                      <v-select
                        v-model="client"
                        label="name"
                        :filterable="true"
                        :searchable="true"
                        :options="selectClients"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group :label="'Obra'" label-for="filter-project">
                      <v-select
                        v-model="project"
                        label="name"
                        :filterable="true"
                        :searchable="true"
                        :options="selectProjects"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <!--<b-col sm="4">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="mr-1 text-nowrap"
                        @click="download('pdf')"
                    >
                      Descargar lista PDF
                    </b-button>
                  </b-col>-->
                  <b-col sm="4">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="mr-1 text-nowrap"
                        @click="download('excel')"
                    >
                      Descargar lista Excel
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <b-table
            hover
            responsive
            :items="items"
            :fields="columns"
            :no-local-sorting="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template #cell(name)="data">
              <p class="m-0">{{ data.item.name }}</p>
              <span v-if="data.item.category" class="font-small">{{
                data.item.category.name
              }}</span>
            </template>
            <template #cell(date_ini)="data">
              {{ dateColumn(data.item.date_ini) }} -
              {{ dateColumn(data.item.date_fin) }}
            </template>
            <template #cell(project)="data">
              <p class="m-0" v-if="data.item.project.name">
                <b-link
                  :to="{
                    name: 'viewProject',
                    params: { id: data.item.project.id },
                  }"
                  class="link"
                  >{{ data.item.project.name }}</b-link
                >
              </p>
              <span v-if="data.item.project.SKU" class="font-small">{{
                data.item.project.SKU
              }}</span>
            </template>
            <template #cell(client)="data">
              <span v-if="data.item.client.name">
                <b-link
                  :to="{
                    name: 'viewClient',
                    params: { id: data.item.client.id },
                  }"
                  class="link"
                  >{{ data.item.client.name }}</b-link
                >
              </span>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusJobsVariant[data.item.status]">
                {{ statusJobsText[data.item.status] }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'viewJob', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <b-link
                  :to="{ name: 'editJob', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span
                  class="d-inline-block px-50 text-dark"
                  @click="openCreateTask(data.item, data.item.task)"
                >
                  <feather-icon icon="CalendarIcon" />
                </span>
                <span
                  @click="deleteJob(data.item.id, data.item.name)"
                  class="d-inline-block px-50 text-danger"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div
            class="d-flex align-items-center justify-content-between mb-1 px-2"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="showCreateTask"
      :hide-footer="true"
      v-if="this.job"
      :title="'Crear tarea para la obra: ' + this.job.name"
      size="lg"
    >
      <form-create-task :job="job" />
    </b-modal>
    <!--  <b-modal
      v-model="showCreateTask"
      :hide-footer="true"
      v-else
      title="Crear tarea para obra: "
      size="lg"
    >
      <form-create-task :job="job" :task="task" />
    </b-modal> -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTable,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BCollapse,
  BButton,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store/index";
import flatPickr from "vue-flatpickr-component";
import { FlatPickrToTimestamp, TimestampToFlatPickr } from "@/libs/helpers";
import vSelect from "vue-select";
import { config } from "@/shared/app.config";
import Stadistics from "@/views/jobs/Stadistics";
import FormCreateTask from "@/views/tasks/FormCreateTask";
import axios from "axios";

export default {
  components: {
    BTable,
    BBadge,
    BLink,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    BRow,
    vSelect,
    BCol,
    BCollapse,
    Stadistics,
    BButton,
    FormCreateTask,
  },
  props: {
    stadistics: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      log: [],
      sortBy: "",
      sortDesc: "",
      statusJobsVariant: config.statusJobsVariants,
      statusJobsText: config.statusJobsText,
      showCreateTask: false,
      job: {},
      task: {},
      visibleFilter: false,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ["10", "15", "25"],
      client: "",
      project: "",
      date_ini: "",
      date_fin: "",
      status: "",
      columns: [
        {
          label: "Nombre",
          key: "name",
          sortable: true,
        },
        {
          label: "Cliente",
          key: "client",
        },
        {
          label: "Obra",
          key: "project",
        },
        {
          label: "Fechas",
          key: "date_ini",
          sortable: true,
        },
        {
          label: "Estado",
          key: "status",
        },
        {
          label: "Imputadas",
          key: "total_hours",
          sortable: true,
        },
        {
          label: "Acciones",
          key: "actions",
          class: "column-actions",
        },
      ],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      items: "jobs/getItems",
      totalItems: "jobs/getTotalItems",
      selectStatus: "jobs/getSelectStatus",
      selectClients: "clients/getSelectClients",
      selectProjects: "projects/getSelectProjects",
    }),
    clientProp() {
      if (this.client) {
        return this.client.id;
      }
      return "";
    },
    projectProp() {
      if (this.project) {
        return this.project.id;
      }
      return "";
    },
    dateiniProp() {
      if (this.date_ini) {
        return FlatPickrToTimestamp(this.date_ini);
      }
      return 0;
    },
    datefinProp() {
      if (this.date_ini) {
        return FlatPickrToTimestamp(this.date_fin);
      }
      return 0;
    },
    statusProp() {
      if (this.status) {
        return this.status;
      }
      return "";
    },
  },
  watch: {
    sortBy() {
      this.currentPage = 1;
      this.chargeData();
    },
    sortDesc() {
      this.currentPage = 1;
      this.chargeData();
    },
    client() {
      this.currentPage = 1;
      this.project = "";
      this.chargeProjects();
      this.chargeData();
    },
    project() {
      this.currentPage = 1;
      this.chargeData();
    },
    date_ini() {
      this.chargeData();
    },
    date_fin() {
      this.chargeData();
    },
    orderby() {
      this.chargeData();
    },
    status() {
      this.chargeData();
    },
  },
  created() {
    this.pageLength = JSON.parse(localStorage.getItem("per_pageJob")) || 10; // value or null
    this.searchTerm = JSON.parse(localStorage.getItem("searchJob")) || ""; // value or null
    this.currentPage = JSON.parse(localStorage.getItem("currentPageJob")) || 1; // value or null
    this.date_ini = JSON.parse(localStorage.getItem("date_iniJob")) || ""; // value or null
    this.date_fin = JSON.parse(localStorage.getItem("date_finJob")) || ""; // value or null
    this.status = JSON.parse(localStorage.getItem("statusJob")) || ""; // value or null
    this.sortBy = JSON.parse(localStorage.getItem("orderByJob")) || ""; // value or null
    this.client = JSON.parse(localStorage.getItem("clientJob")) || ""; // value or null
    this.visibleFilter =
      JSON.parse(localStorage.getItem("visibleFilterJob")) || false; // value or null

    this.chargeData();
    this.getSelectStatus();
    this.getSelectClients();
    this.chargeProjects();
  },
  methods: {
    ...mapActions({
      list: "jobs/getListJobs",
      delete: "jobs/delete",
      getSelectStatus: "jobs/getSelectStatus",
      getSelectClients: "clients/selectClients",
      getSelectProjects: "projects/getSelectProjects",
    }),
    ...mapMutations({
      loading: "app/loading"
    }),
    download(type) {
      this.loading(true);
      let filterDateIni = "";
      let filterDateFin = "";
      let client = "";
      let project = "";
      if (this.date_ini) {
        filterDateIni = FlatPickrToTimestamp(this.date_ini);
      }
      if (this.date_fin) {
        filterDateFin = FlatPickrToTimestamp(this.date_fin);
      }
      if (this.client && this.client !== null) {
        client = this.client.id;
      }
      if (this.project && this.project !== null) {
        project = this.project.id;
      }
      let status = "";
      if (this.status && this.status !== null) {
        status = this.status;
      }
      let orderBy = "";
      if (this.sortBy) {
        const type = this.sortDesc ? "desc" : "asc";
        orderBy = {
          column: this.sortBy,
          type,
        };
      }
      axios({
        url: `${config.webApiBase}/tasks/list/download`, //your url
        data: {
          client_id: client,
          project_id: project,
          search: this.searchTerm,
          date_ini: filterDateIni,
          date_fin: filterDateFin,
          status,
          orderBy,
          type,
        },
        method: 'POST',
        responseType: 'blob', // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        if(type === 'excel') {
          link.setAttribute('download', 'list.xlsx'); //or any other extension
        }else{
          link.setAttribute('download', 'list.pdf'); //or any other extension
        }
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.loading(false);
      });

    },
    chargeData() {
      localStorage.setItem("per_pageJob", JSON.stringify(this.pageLength));
      localStorage.setItem("currentPageJob", JSON.stringify(this.currentPage));
      localStorage.setItem("searchJob", JSON.stringify(this.searchTerm));
      localStorage.setItem("date_iniJob", JSON.stringify(this.date_ini));
      localStorage.setItem("date_finJob", JSON.stringify(this.date_fin));
      localStorage.setItem("statusJob", JSON.stringify(this.status));
      localStorage.setItem("orderByJob", JSON.stringify(this.sortBy));
      localStorage.setItem("clientJob", JSON.stringify(this.client));
      localStorage.setItem(
        "visibleFilterJob",
        JSON.stringify(this.visibleFilter)
      );

      let filterDateIni = "";
      let filterDateFin = "";
      let client = "";
      let project = "";
      if (this.date_ini) {
        filterDateIni = FlatPickrToTimestamp(this.date_ini);
      }
      if (this.date_fin) {
        filterDateFin = FlatPickrToTimestamp(this.date_fin);
      }
      if (this.client && this.client !== null) {
        client = this.client.id;
      }
      if (this.project && this.project !== null) {
        project = this.project.id;
      }
      let status = "";
      if (this.status && this.status !== null) {
        status = this.status;
      }
      let orderBy = "";
      if (this.sortBy) {
        const type = this.sortDesc ? "desc" : "asc";
        orderBy = {
          column: this.sortBy,
          type,
        };
      }
      this.list({
        client,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        date_ini: filterDateIni,
        date_fin: filterDateFin,
        status,
        orderBy,
        project,
      });
    },
    chargeProjects() {
      let client = "";
      if (this.client && this.client !== null) {
        client = this.client.id;
      }
      this.getSelectProjects({ client });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    dateColumn(time) {
      return TimestampToFlatPickr(time);
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    deleteJob(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(id);
          }
        });
    },
    async openCreateTask(job) {
      console.log(job);
      this.job = job;
      this.showCreateTask = true;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
